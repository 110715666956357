<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายละเอียดราคาพิเศษ
          </h1>
        </b-col>
        <b-col lg="8" class="text-right">
          <b-row class="no-gutters d-flex justify-content-end">
            <b-button
              class="btn-main"
              @click="openModalProduct()"
              v-if="!checkStatus"
              >เพิ่มสินค้า</b-button
            >
          </b-row>
        </b-col>
      </CRow>

      <b-container class="container-box bg-white px-4 py-4 mt-4">
        <b-row>
          <b-col md="6">
            <DateTimePicker
              textFloat="วันเริ่มต้น"
              type="datetime"
              isTime
              isRequired
              placeholder="กรุณาเลือกวันที่และเวลา"
              v-model="form.startDate"
              :isValidate="$v.form.startDate.$error"
              :v="$v.form.startDate"
              :isDisabled="checkStatus"
            />
          </b-col>
          <b-col md="6">
            <DateTimePicker
              textFloat="วันสิ้นสุด"
              type="datetime"
              isTime
              isRequired
              placeholder="กรุณาเลือกวันที่และเวลา"
              v-model="form.endDate"
              :isValidate="$v.form.endDate.$error"
              :v="$v.form.endDate"
              :isDisabled="checkStatus"
            />
          </b-col>
        </b-row>
        <b-row class="no-gutters table-detail mt-2">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fieldsProductGroups"
              :items="productGroups"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative">
                  <div
                    class="square-boxs b-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(sku)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
              </template>
              <template v-slot:cell(price)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.price }}</p>
              </template>
              <template v-slot:cell(a)="data">
                <b-form-input
                  :class="[
                    'input-serach',
                    { error: $v.newData.$each.$iter[data.index].price.$error },
                  ]"
                  placeholder="กรอกราคา"
                  type="number"
                  :disabled="checkStatus"
                  v-model="newData[data.index].price"
                ></b-form-input>
                <span
                  class="text-error"
                  v-if="
                    $v.newData.$each.$iter[data.index].price.$error &&
                      !$v.newData.$each.$iter[data.index].price.required
                  "
                  >กรุณากรอกข้อมูล</span
                >
              </template>
              <template v-slot:cell(b)="data">
                <b-form-input
                  :class="[
                    'input-serach',
                    {
                      error:
                        $v.newData.$each.$iter[data.index].storePrice.$error,
                    },
                  ]"
                  placeholder="กรอกราคา"
                  type="number"
                  :disabled="checkStatus"
                  v-model="newData[data.index].storePrice"
                ></b-form-input>
                <span
                  class="text-error"
                  v-if="
                    $v.newData.$each.$iter[data.index].storePrice.$error &&
                      !$v.newData.$each.$iter[data.index].storePrice.required
                  "
                  >กรุณากรอกข้อมูล</span
                >
              </template>
              <template v-slot:cell(action)="data">
                <div class="text-center" v-if="!checkStatus">
                  <b-button
                    variant="link"
                    @click="deleteData(data.item.id)"
                    class="text-dark px-1 py-0"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <span class="text-error" v-if="checkArray == true"
              >กรุณาเพิ่มสินค้า
            </span>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <label class="font-weight-bold main-label">
                การใช้งาน
                <span class="text-danger">*</span>
              </label>
              <div>
                <b-form-checkbox
                  switch
                  class="radio-active ml-3"
                  size="lg"
                  v-model="enabled"
                  :disabled="checkStatus"
                >
                  <span class="ml-2 main-label">
                    {{ enabled ? "เปิด" : "ปิด" }}
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <!-- <div class="d-sm-flex m-3" v-if="productGroups.length > 0">
              <b-pagination
                v-model="filterProduct.PageNo"
                :total-rows="rowsProduct"
                :per-page="filterProduct.PerPage"
                class="m-md-0"
                @change="paginationProduct"
                align="center"
              ></b-pagination>
            </div> -->
          </b-col>
        </b-row>
        <b-row class="px-3 mt-3">
          <b-col class="d-flex justify-content-end align-items-center">
            <router-link :to="'/product-special'">
              <b-button class="button btn-cancel ml-2"
                >ย้อนกลับ</b-button
              ></router-link
            >
            <b-button
              class="btn-main ml-3"
              @click="checkForm()"
              v-if="!checkStatus"
              >บันทึก</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Modal -->
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="ลบ"
      colorBtnConfirm="danger"
      btnCancel="ยกเลิก"
      ref="ModalAlertConfirm"
      @confirm="btnDelete"
    />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />

    <b-modal
      ref="ModalProduct"
      v-model="showModal"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <CRow class="no-gutters px-3 px-sm-0 mt-2">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">เพิ่มสินค้า</h1>
        </b-col>
        <b-col lg="8" class="text-right">
          <div class="d-block d-sm-flex">
            <div class="d-flex justify-content-end w-100">
              <b-input-group class="panel-input-serach search-modal">
                <b-form-input
                  class="input-serach"
                  placeholder="ชื่อสินค้า, SKU"
                  v-model="filterProduct.Search"
                  @keyup="btnSearch()"
                ></b-form-input>
                <b-input-group-prepend @click="btnSearch">
                  <span class="icon-input m-auto pr-2">
                    <font-awesome-icon icon="search" title="View" />
                  </span>
                </b-input-group-prepend>
              </b-input-group>
            </div>
          </div>
        </b-col>
      </CRow>
      <div class="mt-4 bg-white px-0">
        <b-row class="no-gutters table-detail">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fieldsProduct"
              :items="itemsProduct"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(ids)="data">
                <b-form-checkbox
                  size="lg"
                  class="ml-3"
                  :value="data.item.id"
                  v-model="selected"
                  @input="handleItem(data.item.id)"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(imageUrl)="data">
                <div class="position-relative">
                  <div
                    class="square-boxs b-contain"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.imageUrl + ')',
                    }"
                  ></div>
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(sku)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.sku }}</p>
              </template>
              <template v-slot:cell(price)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.price }}</p>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline footer-paginate justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filterProduct.PageNo"
                :total-rows="rowsProduct"
                :per-page="filterProduct.PerPage"
                class="m-md-0"
                @change="paginationProduct"
                align="center"
              ></b-pagination>
            </div>
            <div class="d-sm-flex m-3">
              <b-button class="button btn-cancel ml-2" @click="hide()"
                >ย้อนกลับ</b-button
              >
              <b-button
                :disabled="selected.length == 0"
                class="btn-main ml-3"
                @click="handleData"
                >เพิ่มสินค้า</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import DateTimePicker from "@/components/inputs/DateTimePicker";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";

import { required } from "vuelidate/lib/validators";

export default {
  name: "ProductHighlight",
  components: {
    DateTimePicker,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    ModalAlert,
  },
  data() {
    return {
      id: this.$route.params.id,
      form: {
        startDate: "",
        endDate: "",
        enabled: "",
        products: [],
      },
      modalMessage: "",
      showModal: false,
      isBusy: false,
      enabled: true,
      itemsProduct: [],
      rowsProduct: 0,
      filterProduct: {
        PageNo: 1,
        PerPage: 5,
        Search: "",
        Status: [],
        CategoryId: [],
        SortByOrderOrId: 2,
      },
      fieldsProduct: [
        { key: "ids", label: "เลือก", class: "w-50px text-nowrap" },
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px text-nowrap" },
        {
          key: "name",
          label: "รายละเอียดสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "sku",
          label: "รหัสสินค้า",
          class: "w-100px text-nowrap",
        },
        { key: "price", label: "ราคา", class: "w-100px text-nowrap" },
      ],
      fieldsProductGroups: [
        { key: "imageUrl", label: "ภาพประกอบ", class: "w-100px text-nowrap" },
        {
          key: "name",
          label: "รายละเอียดสินค้า",
          class: "w-100px text-nowrap",
        },
        {
          key: "sku",
          label: "รหัสสินค้า",
          class: "w-100px text-nowrap",
        },
        { key: "price", label: "ราคาตั้งต้น", class: "w-100px text-nowrap" },
        {
          key: "a",
          label: "ราคาขายลูกค้าทั่วไป",
          class: "w-100px text-nowrap",
        },
        { key: "b", label: "ราคาร้านค้าทั่วไป", class: "w-100px text-nowrap" },
        { key: "action", label: "", class: "w-100px text-nowrap" },
      ],
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
      selected: [],
      selectedData: [],
      productGroups: [],
      newData: [],
      checkArray: false,
      checkStatus: false,
      formDelete: {
        id: "",
        startDate: "",
      },
    };
  },
  validations() {
    return {
      form: {
        startDate: { required },
        endDate: { required },
      },
      newData: {
        $each: {
          price: { required },
          storePrice: { required },
        },
      },
    };
  },
  created: async function() {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function() {
      this.isBusy = true;
      if (this.id != 0) {
        let data = await this.$callApi(
          "get",
          `${this.$baseUrl}/api/product/GetProductPriceByDate?dateTime=${this.id}`,
          null,
          this.$headers,
          null
        );

        if (data.result == 1) {
          if (data.detail.status == "ดำเนินการ") {
            this.checkStatus = true;
          }

          this.form.startDate = `${data.detail.startDate.split(" ")[0]}T${
            data.detail.startDate.split(" ")[1]
          }.000+07:00`;
          this.form.endDate = `${data.detail.endDate.split(" ")[0]}T${
            data.detail.endDate.split(" ")[1]
          }.000+07:00`;

          this.enabled = data.detail.enabled == 1 ? true : false;

          if (data.detail.products.length > 0) {
            for (const i of data.detail.products) {
              this.newData.push({
                price: i.customPrice,
                storePrice: !i.storePrice ? 0 : i.storePrice,
              });

              let detail = await this.$callApi(
                "get",
                `${this.$baseUrl}/api/product/productDetail/${i.productId}`,
                null,
                this.$headers,
                null
              );

              if (detail.result == 1) {
                let result = detail.detail.product;
                this.productGroups.push({
                  id: result.id,
                  name: result.urlKey,
                  imageUrl: `${this.$imagePath}/${result.imageUrl}`,
                  sku: result.sku,
                  price: result.price,
                });
              }
            }
          }
        }
      }

      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/List`,
        null,
        this.$headers,
        this.filterProduct
      );

      if (resData.result == 1) {
        this.itemsProduct = resData.detail.dataList;
        this.rowsProduct = resData.detail.count;
      }
      this.isBusy = false;
    },
    async btnDelete() {
      this.$refs.ModalAlertConfirm.hide();
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/Product/DeleteProductPrice?productId=${this.formDelete.id}&&startDate=${this.formDelete.startDate}`,
        null,
        this.$headers,
        null
      );

      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 2000);
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
    deleteData(id) {
      if (this.id == 0) {
        for (let index = 0; index < this.productGroups.length; index++) {
          if (this.productGroups[index].id === id) {
            this.productGroups.splice(index, 1);
            this.newData.splice(index, 1);
          }
        }
      } else {
        this.modalMessage = "คุณต้องการลบรายการใช่หรือไม่?";
        this.$refs.ModalAlertConfirm.show();
        this.formDelete = {
          id: id,
          startDate: this.form.startDate,
        };
      }
    },
    checkForm: async function() {
      let checkProduct = await this.checkProduct();
      this.$v.form.$touch();
      this.$v.newData.$touch();
      if (this.$v.form.$error || this.$v.newData.$error || !checkProduct) {
        return;
      }
      this.form.enabled = this.enabled == true ? 1 : 0;
      const path =
        this.id == 0
          ? `${this.$baseUrl}/api/Product/CreateProductPrice`
          : `${this.$baseUrl}/api/Product/UpdateProductPrice`;

      this.$refs.modalLoading.show();
      let resData = await this.$callApi(
        "post",
        path,
        null,
        this.$headers,
        this.form
      );

      this.$refs.modalLoading.hide();
      if (resData.result == 1) {
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$router.push({
            path: `/product-special`,
          });
        }, 2000);
      } else {
        this.modalMessage = resData.message;
        this.$refs.modalAlertError.show();
        setTimeout(() => {
          this.$refs.modalAlertError.hide();
        }, 2000);
      }
    },
    async checkProduct() {
      if (this.productGroups.length == 0) {
        this.checkArray = true;
        return false;
      }

      for (let index = 0; index < this.productGroups.length; index++) {
        this.newData[index].id = this.productGroups[index].id;
      }
      this.form.products = this.newData;
      return true;
    },
    btnSearch() {
      this.filterProduct.PageNo = 1;
      this.getList();
    },
    hide() {
      this.showModal = false;
    },
    handleData() {
      this.hide();
      this.checkArray = false;
      this.$v.newData.$reset();
      if (this.productGroups.length > 0) {
        for (let index = 0; index < this.selectedData.length; index++) {
          let find = this.productGroups.findIndex((e) => {
            return e.id === this.selectedData[index].id;
          });
          if (find === -1) this.productGroups.push(this.selectedData[index]);
        }
      } else this.productGroups = this.selectedData;

      for (const i of this.productGroups) {
        this.newData.push({
          id: "",
          price: "",
          storePrice: "",
        });
      }
    },
    handleItem() {
      this.selectedData = [];
      for (const i of this.selected) {
        let index = this.itemsProduct.findIndex((e) => {
          return e.id === i;
        });
        this.itemsProduct[index].productId = this.itemsProduct[index].id;
        this.selectedData.push(this.itemsProduct[index]);
      }
    },
    openModalProduct() {
      this.selected = [];
      this.selectedData = [];
      this.showModal = true;
    },
    paginationProduct(Page) {
      this.filterProduct.PageNo = Page;
      this.getList();
    },
  },
};
</script>

<style scoped>
.input-serach.error {
  border: 1px solid #e55353 !important;
}

.text-error {
  color: #ff0000;
  font-size: 14px;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}
</style>
